<template>
  <div class="personal-center bg-dark">
    <el-image
      v-if="personal.cover"
      @contextmenu="handleMouse"
      :src="personal.cover"
      :fit="'cover'"
      class="cover-img"
    ></el-image>
    <div v-else class="default-bg">
      <el-image
        @contextmenu="handleMouse"
        :src="personal_bg"
        :fit="'cover'"
        class="cover-img"
      ></el-image>
    </div>
    <div class="personal-detail">
      <div class="detail-num">
        <ul>
          <li>
            粉丝<span>{{ personal.fans }}</span
            >|
          </li>
          <li>
            关注<span>{{ personal.guanzhu }}</span
            >|
          </li>
          <li>
            作品<span>{{ personal.resopurcenum }}</span
            >|
          </li>
          <li>
            人气<span>{{ personal.renqi }}</span>
          </li>
        </ul>
        <div class="upload-cover-btn" v-if="user_id == PageUserId">
          设置封面<el-upload
            drag
            action=""
            accept=".jpg,.png,.jpeg"
            :show-file-list="false"
            multiple
            :http-request="customUploadPicture"
          >
          </el-upload>
        </div>
      </div>
      <div class="detail-info">
        <el-avatar
          :size="111"
          :src="personal.headimage || defaultimg"
          v-if="personal.headimage"
        ></el-avatar>
        <el-avatar :size="111" icon="el-icon-user-solid" v-else></el-avatar>
        <div class="name">
          {{ personal.nickname }}
          <img
            src="@/assets/images/male.png"
            class="sex"
            v-if="personal.sex === '1'"
          />
          <img src="@/assets/images/female.png" class="sex" v-else />
        </div>
        <div class="address-box">
          <img v-if="personal.address" src="@/assets/images/address.png" />
          <span v-if="personal.address" class="address">{{
            personal.address
          }}</span>
          <div
            class="follow"
            v-if="isGuanzhu"
            @click="handleFollow(personal.id)"
          >
            已关注
          </div>
          <div
            class="follow"
            v-else
            style="cursor: pointer"
            @click="handleFollow(personal.id)"
          >
            <i class="iconfont el-icon-plus"></i>关注
          </div>
        </div>
        <div class="honor" v-if="personal.honor">{{ personal.honor }}</div>
        <div class="intro">{{ personal.description }}</div>
      </div>
      <div
        class="personal-works"
        :class="personal.honor ? 'personal-hworks' : ''"
      >
        <div class="tabs">
          <ul>
            <li
              v-for="item in tabs"
              :key="item.value"
              @click="clickTab(item.value)"
            >
              {{ item.label }}
              <div class="line" v-if="activeTab === item.value"></div>
            </li>
          </ul>
        </div>
        <div class="info" v-if="activeTab === '5'">
          <p class="title title-mark">基本信息</p>
          <div class="info-box">
            <div class="info-con">
              <span>用户名</span>
              <p>{{ personal.nickname }}</p>
            </div>
            <div class="info-con">
              <span>性别</span>
              <p>
                {{
                  personal.sex ? (personal.sex === "1" ? "男" : "女") : "未知"
                }}
              </p>
            </div>
            <div class="info-con">
              <span>职业</span>
              <p>{{ personal.occupation || "-" }}</p>
            </div>
            <div class="info-con">
              <span>简介</span>
              <p>{{ personal.description || "尚无个人简介" }}</p>
            </div>
          </div>
        </div>
        <div class="picture" v-if="activeTab === '1'">
          <div class="row result-item" v-if="pictureList.length > 0">
            <div
              class="img-block type-1"
              v-for="item in pictureList"
              :key="item.id"
            >
              <div class="img-checking" v-if="item.status == '1'">
                <img :src="require('@/assets/images/auditing.png')" />
                <span>
                  审核中
                </span>
              </div>
              <img
                class="cover"
                @contextmenu="handleMouse"
                style="height: 334px;min-width: 100%;cursor:pointer"
                :src="item.resource_path"
                @click="handleDetail(item, 'picture')"
              />
              <i
                v-if="item.user_id == user_id"
                class="delete-icon el-icon-circle-close"
                @click="showDialog(item.id)">
              </i>
              <div class="grid-text" v-if="item.status == '2'">
                <div class="grid-oper">
                  <p class="name">{{ item.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(item.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item)"
                    />
                    <img
                      v-if="item.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(item.id, pictureList)"
                      class="m-l"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(item.id, pictureList)"
                      class="m-l"
                    />
                    <span>{{ item.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-style">
            <p>暂时还没有相关信息</p>
          </div>
        </div>
        <div class="video-box" v-if="activeTab === '2'">
          <div class="video-list" v-if="videoList.length > 0">
            <div class="grid-col" v-for="item in videoList" :key="item.id">
              <div
                class="grid-content"
                @mouseenter="mouseenter(item)"
                @mouseleave="mouseleave(item)"
              >
                <div class="video-checking" v-if="item.status == '1'">
                  <img :src="require('@/assets/images/auditing.png')" />
                  <span>审核中</span>
                </div>
                <el-image
                  style="height: 239px;min-width: 100%"
                  @contextmenu="handleMouse"
                  :src="item.resource_fimage"
                  :fit="'cover'"
                  lazy
                  :class="{ 'current-image': item.id === current }"
                ></el-image>
                <i
                  v-if="item.user_id == user_id"
                  class="delete-icon el-icon-circle-close"
                  @click="showDialog(item.id)">
                </i>
                <div
                  class="sample-player-box"
                  @click="handleDetail(item, 'video')"
                >
                  <div class="info-block right-top">{{ item.quality }}</div>
                  <div class="info-block left-top">{{ item.duration }}</div>
                  <div class="video-player">
                    <video
                      @contextmenu="handleMouse"
                      :src="item.resource_path"
                      :autoplay="true"
                      loop="loop"
                      class="video"
                      v-if="item.id === current"
                    >
                      您的浏览器不支持 video 标签。
                    </video>
                  </div>
                </div>
                <div class="grid-text" v-if="item.status == '2'">
                  <div class="grid-oper">
                    <p class="name">{{ item.title }}</p>
                    <div>
                      <img
                        src="@/assets/images/collection-1.png"
                        @click="handleCollection(item.id)"
                      />
                      <img
                        src="@/assets/images/forward-1.png"
                        @click="handleShare(item)"
                      />
                      <img
                        v-if="item.is_zan"
                        src="@/assets/images/like-fill.png"
                        @click="handleLike(item.id, videoList)"
                        class="m-l"
                      />
                      <img
                        v-else
                        src="@/assets/images/like-1.png"
                        @click="handleLike(item.id, videoList)"
                        class="m-l"
                      />
                      <span>{{ item.fabulous }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-style">
            <p>暂时还没有相关信息</p>
          </div>
        </div>
        <div class="like" v-if="activeTab === '3' || activeTab === '4'">
          <div>
            <p class="title title-mark" style="margin:82px 0 0 16px;">
              图片
              <span
                class="more"
                @click="morePicture"
                v-if="pictureList.length > 80"
                >查看更多>></span
              >
            </p>
            <div
              class="row result-item"
              style="padding:20px 0 84px;"
              v-if="pictureList.length > 0"
            >
              <template v-for="(item, index) in pictureList">
                <div class="img-block type-1" :key="item.id" v-if="index < 80">
                  <img
                    @contextmenu="handleMouse"
                    class="cover"
                    style="height: 334px; min-width: 100%; cursor: pointer"
                    :src="item.resource_path"
                    @click="handleDetail(item, 'picture')"
                  />
                  <div class="grid-text">
                    <div class="grid-oper">
                      <p class="name">{{ item.title }}</p>
                      <div>
                        <img
                          src="@/assets/images/collection-1.png"
                          @click="handleCollection(item.id)"
                        />
                        <img
                          src="@/assets/images/forward-1.png"
                          @click="handleShare(item)"
                        />
                        <img
                          v-if="item.is_zan"
                          src="@/assets/images/like-fill.png"
                          @click="handleLike(item.id, pictureList)"
                          class="m-l"
                        />
                        <img
                          v-else
                          src="@/assets/images/like-1.png"
                          @click="handleLike(item.id, pictureList)"
                          class="m-l"
                        />
                        <span>{{ item.fabulous }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="empty-style">
              <p>暂时还没有相关信息</p>
            </div>
          </div>
          <div>
            <p class="title title-mark" style="margin: 0 0 0 16px">
              视频<span
                class="more"
                @click="moreVideo"
                v-if="videoList.length > 80"
                >查看更多>></span
              >
            </p>
            <div
              class="video-list"
              v-if="videoList.length > 0"
              style="padding-top: 20px"
            >
              <template v-for="(item, index) in videoList">
                <div class="grid-col" :key="item.id" v-if="index < 80">
                  <div
                    class="grid-content"
                    @mouseenter="mouseenter(item)"
                    @mouseleave="mouseleave(item)"
                  >
                    <el-image
                      style="height: 239px; min-width: 100%"
                      @contextmenu="handleMouse"
                      :src="item.resource_fimage"
                      :fit="'cover'"
                      lazy
                      :class="{ 'current-image': item.id === current }"
                    ></el-image>
                    <div
                      class="sample-player-box"
                      @click="handleDetail(item, 'video')"
                    >
                      <div class="info-block right-top">{{ item.quality }}</div>
                      <div class="info-block left-top">{{ item.duration }}</div>
                      <div class="video-player">
                        <video
                          @contextmenu="handleMouse"
                          :src="item.resource_path"
                          :autoplay="true"
                          loop="loop"
                          class="video"
                          v-if="item.id === current"
                        >
                          您的浏览器不支持 video 标签。
                        </video>
                      </div>
                    </div>
                    <div class="grid-text">
                      <div class="grid-oper">
                        <p class="name">{{ item.title }}</p>
                        <div>
                          <img
                            src="@/assets/images/collection-1.png"
                            @click="handleCollection(item.id)"
                          />
                          <img
                            src="@/assets/images/forward-1.png"
                            @click="handleShare(item)"
                          />
                          <img
                            v-if="item.is_zan"
                            src="@/assets/images/like-fill.png"
                            @click="handleLike(item.id, videoList)"
                            class="m-l"
                          />
                          <img
                            v-else
                            src="@/assets/images/like-1.png"
                            @click="handleLike(item.id, videoList)"
                            class="m-l"
                          />
                          <span>{{ item.fabulous }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="empty-style">
              <p>暂时还没有相关信息</p>
            </div>
          </div>
        </div>
        <div
          class="comment-all-box"
          v-if="activeTab === '1' || activeTab === '2'"
        >
          <div>
            <el-pagination
              :current-page.sync="currentPage"
              @current-change="handlePersonalCurrentChange"
              :page-size="80"
              layout="prev, pager, next"
              :total="personTotal"
              v-if="personTotal > 0"
            >
            </el-pagination>
          </div>

          <div class="picture-detail">
            <div class="browse box">
              <div class="browse-num">
                <img src="@/assets/images/eye.png" />
                <span>浏览</span>
                <span>{{ browseUsers.length }}</span>
              </div>
              <div class="browse-user">
                <el-row :gutter="20">
                  <template v-if="browseUsers.length > 0">
                    <el-col
                      :span="8"
                      v-for="(item, index) in browseUsers"
                      :key="item.id"
                    >
                      <div :key="item.id" v-if="index < 8" class="user-item">
                        <div @click="jumpUCenter(item.id)">
                          <el-avatar
                            :size="52"
                            :src="item.headimage || defaultimg"
                            v-if="item.headimage"
                          ></el-avatar>
                          <el-avatar
                            :size="52"
                            icon="el-icon-user-solid"
                            v-else
                          ></el-avatar>
                        </div>

                        <p class="name">{{ item.nickname }}</p>
                        <p class="time">{{ formatTime(item.visit_time) }}</p>
                      </div>
                    </el-col>
                  </template>
                  <el-col :span="8">
                    <div class="user-item ellipsis">...</div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="board box">
              <div class="board-message">
                <p class="title title-mark">留言板</p>
                <div class="comment-input">
                  <el-input
                    type="textarea"
                    :rows="2"
                    resize="none"
                    show-word-limit
                    maxlength="180"
                    placeholder="说点什么呢....."
                    v-model="comment"
                  >
                  </el-input>
                  <div class="comment-bg" @click="sendComment">
                    <img src="@/assets/images/comment-bg.png" />
                    <span>留言</span>
                  </div>
                </div>
              </div>
              <div class="comment-con" v-if="commentList.length > 0">
                <div
                  v-for="(item, i) in commentList"
                  :key="item.id"
                  class="comment-list"
                >
                  <div class="comment-list-level comment-list-level-1">
                    <div class="comment-item">
                      <div
                        @click="jumpUCenter(item.user_id)"
                        class="comment-avatar"
                      >
                        <el-avatar
                          :size="52"
                          :src="item.headimage || defaultimg"
                        ></el-avatar>
                      </div>

                      <div class="comment-box">
                        <p class="name">{{ item.user_name }}</p>
                        <p class="comment">{{ item.comment }}</p>
                      </div>
                    </div>
                    <div class="comment-reply">
                      <div
                        @click="
                          showReplyInput(
                            i,
                            item.id,
                            item.user_name,
                            item.user_id,
                            item.parent_id
                          )
                        "
                      >
                        <img src="@/assets/images/reply.png" />
                        <span>{{ formatTime(item.time) }}</span>
                      </div>

                      <div
                        class="comment-delete"
                        @click="
                          handleDelete(
                            i,
                            item.id,
                            item.user_name,
                            item.user_id,
                            item.parent_id
                          )
                        "
                      >
                        <span>删除</span>
                      </div>
                    </div>
                  </div>
                  <template v-if="item.children">
                    <div
                      class="comment-list-level comment-list-level-2"
                      :class="{ 'first-level-2': index == 0 }"
                      v-for="v in item.children"
                      :key="v.id"
                    >
                      <div class="comment-item">
                        <div
                          @click="jumpUCenter(item.user_id)"
                          class="comment-avatar"
                        >
                          <el-avatar
                            :size="35"
                            :src="v.headimage || defaultimg"
                          ></el-avatar>
                        </div>

                        <div class="comment-box">
                          <p class="name">{{ v.user_name }}</p>
                          <p class="comment">
                            <span>@{{ v.to }}:</span>{{ v.comment }}
                          </p>
                        </div>
                      </div>
                      <div class="comment-reply">
                        <div
                          @click="
                            showReplyInput(
                              i,
                              v.id,
                              v.user_name,
                              v.user_id,
                              v.parent_id
                            )
                          "
                        >
                          <img src="@/assets/images/reply.png" />
                          <span>{{ formatTime(v.time) }}</span>
                        </div>
                        <div
                          class="comment-delete"
                          @click="
                            handleDelete(
                              i,
                              v.id,
                              v.user_name,
                              v.user_id,
                              v.parent_id
                            )
                          "
                        >
                          <span>删除</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <div
                    v-show="_inputShow(i)"
                    class="my-reply my-comment-reply comment-form"
                  >
                    <div class="form-box">
                      <div class="form-box-left">
                        <el-avatar :size="35" :src="userAvatar"></el-avatar>
                        <div class="input-box comment-input reply-input">
                          <el-input
                            type="textarea"
                            :rows="2"
                            resize="none"
                            show-word-limit
                            maxlength="80"
                            :placeholder="placeholder"
                            v-model="replyComment"
                          >
                          </el-input>
                        </div>
                      </div>
                      <div class="action-box">
                        <div class="reply-btn-box">
                          <span class="reply-btn" @click="sendCommentReply(i)"
                            >发表评论</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="no-data" v-else>暂时还没有相关信息</div>
              <div
                class="more-comment"
                @click="handleMoreComment"
                v-if="moreComment"
              >
                查看全部留言<span>({{ total }})</span>
              </div>
              <div
                class="more-comment"
                @click="handleClose"
                v-if="!moreComment && showShouQi"
              >
                点击收起
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--分享组件-->
    <poster ref="Poster"></poster>
    <el-dialog
      title="删除提示"
      :visible.sync="dialogVisible"
      width="30%">
      <span>是否确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deletePicture()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { timeTransform } from "@/utils/utils.js";
import poster from "@/components/sharePoster/index.vue";
import pageMixin from "@/mixins/tbPage";
import toLogin from "@/mixins/toLogin";
export default {
  name: "index",
  mixins: [pageMixin, toLogin],
  components: { poster },
  data() {
    return {
      query: {}, //评论默认参数
      personQuery: {}, //个人中心默认参数
      index: "0",
      isGuanzhu: false, //是否关注
      PageUserId: "", //页面所属个人中心id
      user_id: "", //当前用户id
      moreComment: false, //是否显示查看更多
      showShouQi: false, //是否显示收起
      personal_bg: require("../../assets/images/cover.png"), //个人中心封面
      personal: {
        cover: require("../../assets/images/cover.png"), //个人中心封面
        headPortrait: require("../../assets/images/headPortrait.png"),
        name: "我不猜你是谁",
        fans: 12,
        follow: 0,
        works: 10,
        popularity: 134,
        intro: "机会就像秃子头上的一根毛，你抓住就抓住了，抓不住就没了。",
        sex: "male",
        occupation: "搬砖工",
      },
      tabs: [
        {
          label: "图片",
          value: "1",
        },
        {
          label: "视频",
          value: "2",
        },
        {
          label: "喜欢",
          value: "3",
        },
        {
          label: "收藏",
          value: "4",
        },
        {
          label: "资料",
          value: "5",
        },
      ],
      activeTab: "1",
      pictureList: [],
      browseUsers: [
        // {
        //   headimage:
        //     "http://gjdl960.oss-cn-beijing.aliyuncs.com/202204/7ae9b390f67e833263e47f85774deea8.png",
        //   nickname: "紫檀未灭123",
        //   visit_time: "2022-05-01 21:13:48"
        // }
      ],
      comment: "", //评论
      replyComment: "", //回复评论
      defaultimg: require("../../assets/images/headPortrait.png"),
      commentList: [],
      videoList: [],
      current: "",
      currentPage: 1,
      currentCommentPage: 1,
      total: 20,
      personTotal: 0,
      placeholder: "请输入评论内容...",
      replyUsername: "",
      dialogVisible: false, // 删除对话框是否可见
      deleteId: '', // 当前要删除的图片id
    };
  },
  created() {
    const { PageUserId } = this.$route.query;
    this.PageUserId = PageUserId;
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    this.query = {
      type: 3,
      user_id: this.PageUserId,
    };
  },
  mounted() {
    this.personQuery = {
      type: 1,
      visit_user_id: this.user_id,
      user_id: this.PageUserId,
    };
    // 调取评论列表方法
    this.getPage();
    //调取个人信息列表
    this.getPersonPage();
  },
  computed: {
    userAvatar() {
      const user_info = localStorage.getItem("user_info");
      return user_info && JSON.parse(user_info).headimage
        ? JSON.parse(user_info).headimage
        : this.defaultimg;
    },
  },
  watch: {
    $route() {
      const { PageUserId } = this.$route.query;
      this.PageUserId = PageUserId;
      const user_id = localStorage.getItem("user_id");
      this.user_id = user_id;
      this.query = {
        type: 3,
        user_id: this.PageUserId,
      };
      this.personQuery = {
        type: 1,
        visit_user_id: this.user_id,
        user_id: this.PageUserId,
      };
      // 调取评论列表方法
      this.getPage();
      //调取个人信息列表
      this.getPersonPage();
    },
  },
  methods: {
    handleMouse(e) {
      e.preventDefault();
    },
    customUploadPicture(data) {
      const formData = new FormData();
      formData.append("file", data.file);
      this.$api.postUpImages(formData).then((res) => {
        if (res.data.code === 1) {
          console.log(res);
          this.personal.cover = res.data.data.yuantu;
          //调取保存接口
          this.$api
            .editUserinfo({ user_id: this.user_id, cover: this.personal.cover })
            .then((res) => {
              console.log(999, res);
            });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //点击删除评论按钮，后来新加功能
    handleDelete(i, id, user_name, user_id, parent_id) {
      console.log("handleDelete", i, id, user_name, user_id, parent_id);
      this.$api.postDeleteComment({ ids: id }).then((res) => {
        if (res.data.code === 1) {
          this.$message.success("删除成功");
          // 调取评论列表方法刷新
          this.getPage();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    jumpUCenter(user_id) {
      this.$router.push({
        name: "personalCenter",
        query: { PageUserId: user_id },
      });
      // location.reload();
    },
    handleDetail(item, type) {
      console.log(item, "item");
      if (type === "picture") {
        this.$router.push({
          name: "picturePreview",
          query: { id: item.id },
        });
      }
      if (type === "video") {
        this.$router.push({
          name: "videoPreview",
          query: { id: item.id },
        });
      }
    },
    _inputShow(i) {
      // console.log("_inputShow i===" + i, this.commentList[i].inputShow);
      return this.commentList[i].inputShow;
    },
    _inputHide(i) {
      this.commentList[i].inputShow = false;
    },
    showReplyInput(i, id, user_name, user_id, parent_id) {
      console.log(user_name, "user_name");
      this.replyUsername = user_name;
      this.placeholder = "@" + user_name;
      if (this.index !== i) {
        this.commentList[this.index].inputShow = false;
      }
      this.index = i;
      this.commentList[i].inputShow = !this.commentList[i].inputShow;
      this.parent_id = parent_id;
      this.id = id;
      console.log(987654321, user_name, user_id, parent_id);
      // this.to = user_name;
      // this.toId = id;
    },
    async sendComment() {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      if (!this.comment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "留言不能为空",
        });
      } else {
        console.log(99999, this.PageUserId);
        const { data } = await this.$api.postAddComment({
          type: 3,
          user_id: this.user_id,
          page_user_id: this.PageUserId,
          content: this.comment,
        });
        if (data.code === 1) {
          this.$message({
            message: data.msg,
            type: "success",
          });
          let a = {};
          a.time = data.data.create_time;
          a.comment = data.data.content;
          a.user_name = data.data.user_name;
          a.user_id = data.data.user_id;
          a.parent_id = data.data.parent_id;
          a.id = data.data.id;
          a.inputShow = false;
          a.children = [];
          a.headimage = data.data.headimage || this.defaultimg;
          this.commentList.unshift(a);
          this.comment = "";
        } else {
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      }
    },
    async sendCommentReply(i) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      if (!this.replyComment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "评论不能为空",
        });
      } else {
        console.log(99999, this.PageUserId);
        const { data } = await this.$api.postAddComment({
          type: 3,
          user_id: this.user_id,
          page_user_id: this.PageUserId,
          content: this.replyComment,
          parent_id: this.id,
        });
        if (data.code === 1) {
          this.$message({
            message: data.msg,
            type: "success",
          });
          let a = {};
          a.time = data.data.create_time;
          a.comment = data.data.content;
          a.user_name = data.data.user_name;
          a.user_id = data.data.user_id;
          a.parent_id = data.data.parent_id;
          a.id = data.data.id;
          a.inputShow = false;
          a.headimage = data.data.headimage || this.defaultimg;
          a.to = this.replyUsername;
          this.commentList[i].children.unshift(a);
          console.log(123, this.commentList[i]);
          this.replyComment = "";
          this._inputHide(i);
        } else {
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      }
    },
    handleFollow(other_user_id) {
      console.log("添加关注");
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const user_id = this.user_id; //当前用户id
      this.$api.postFollow({ user_id, other_user_id }).then((res) => {
        if (res.data.code === 1) {
          this.$message.success(res.data.msg);
          this.isGuanzhu = res.data.data.is_follow;
          if (this.isGuanzhu) {
            this.personal.fans = this.personal.fans + 1;
          } else {
            this.personal.fans = this.personal.fans - 1;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //评论接口列表
    async getList(params) {
      console.log("getListgetList", params);
      const { data } = await this.$api.getCommentList(params);
      if (data.code === 1) {
        console.log("获取评论列表", data);
        if (data?.data.length > 10) {
          this.commentList = data.data.slice(0, 10);
          this.originCommentList = data.data.slice(0, 10);
          this.moreCommentList = data.data.splice(10);
          this.moreComment = true;
        } else {
          this.commentList = data.data;
          this.moreComment = false;
          this.originCommentList = data.data.slice(0, 10);
        }

        this.total = data.count;
      }
    },
    //个人中心信息列表
    async getPersonalList(params) {
      const { data } = await this.$api.getPersonalList(params);
      if (data.code === 1) {
        console.log(
          "获取个人中心信息列表",
          this.activeTab,
          data.data.resource_count
        );
        if (this.activeTab == 1) {
          this.pictureList = data.data.resource_info;
          this.browseUsers = data.data.vistorinfo;
          this.personTotal = data.data.resource_count;
          this.personal = data.data.userinfo;
          this.isGuanzhu = data.data.userinfo.is_guanzhu;
        } else if (this.activeTab == 2) {
          this.videoList = data.data.resource_info;
          this.browseUsers = data.data.vistorinfo;
          this.personTotal = data.data.resource_count;
          this.personal = data.data.userinfo;
          this.isGuanzhu = data.data.userinfo.is_guanzhu;
        } else if (this.activeTab == 3) {
          this.pictureList = data.data.resource_info;
          this.videoList = data.data.video_info;
          this.browseUsers = data.data.vistorinfo;
          this.personal = data.data.userinfo;
          this.isGuanzhu = data.data.userinfo.is_guanzhu;
        } else if (this.activeTab == 4) {
          this.pictureList = data.data.resource_info;
          this.videoList = data.data.video_info;
          this.browseUsers = data.data.vistorinfo;
          this.personal = data.data.userinfo;
          this.isGuanzhu = data.data.userinfo.is_guanzhu;
        } else {
          this.browseUsers = data.data.vistorinfo;
          this.personal = data.data.userinfo;
          this.isGuanzhu = data.data.userinfo.is_guanzhu;
        }
      }
    },
    //查看更多评论
    handleMoreComment() {
      this.moreComment = false;
      this.showShouQi = true;
      this.commentList = this.commentList.concat(this.moreCommentList);
    },
    //点击收起更多评论
    handleClose() {
      this.moreComment = true;
      this.commentList = this.originCommentList;
    },
    //点击tab切换
    clickTab(val) {
      console.log("123456789clickTab", val);
      this.activeTab = val;
      this.currentPage = 1;
      const pagination = { currentPage: 1 };
      Object.assign(this.pagination, pagination);
      this.personQuery = {
        type: val,
        visit_user_id: this.user_id,
        user_id: this.PageUserId,
      };
      this.getPersonPage();
    },
    formatTime(time) {
      return timeTransform(time);
    },
    mouseenter(item) {
      if (item.status == "1") {
        return;
      }
      this.current = item.id;
    },
    mouseleave() {
      this.current = "";
    },
    moreVideo() {
      const type = this.activeTab == "3" ? "like" : "shoucang";
      this.$router.push({
        name: "moreVideoList",
        query: { type: type, id: this.PageUserId },
      });
    },
    morePicture() {
      console.log(123, this.PageUserId);
      const type = this.activeTab == "3" ? "like" : "shoucang";
      this.$router.push({
        name: "morePictureList",
        query: { type: type, id: this.PageUserId },
      });
    },
    async handleCollection(id) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id,
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning",
        });
      }
    },
    async handleShare(info) {
      console.log(99999999, info);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const qrContent =
        window.location.origin + "#/picture/preview?id=" + info.id + "";
      const posterInfo = {
        nickname: info.nickname,
        headimage: info.headimage,
        user_description: info.user_description,
        url: info.resource_fimage || info.resource_path,
        title: info.title,
        description: info.description,
        resource_id: info.id,
        content: qrContent,
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id,
      });
      if (data.code === 1) {
        console.log(data.msg);
      }
    },
    async handleLike(id, list) {
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      console.log(789, id);
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id,
      });
      const resource = list.filter((i) => i.id === id)[0];
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success",
        });
        resource.fabulous = resource.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning",
        });
        resource.fabulous = resource.fabulous - 1;
      }
      resource.is_zan = !resource.is_zan;
    },
    showDialog(id) {
      this.dialogVisible = true;
      this.deleteId = id;
    },
    async deletePicture() {
      const { data } = await this.$api.deletePicture({ ids: this.deleteId });
      if (data.code === 1) {
        this.dialogVisible = false;
        this.$message.success("删除成功");
        this.getPersonPage();
      }
    }
  },
};
</script>

<style scoped lang="scss">
.personal-center {
  padding-bottom: 172px;
  .cover-img {
    width: 100%;
    height: 742px;
  }
  .default-bg {
    width: 100%;
    height: 742px;
    background: #cdd5f0;
  }
  .personal-detail {
    margin: 0 96px;
    position: relative;
    .detail-num {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      ul {
        display: flex;
        li {
          margin-left: 10px;
          font-family: PingFangSC-Light;
          span {
            margin: 0 10px 0 5px;
          }
        }
      }
      .upload-cover-btn {
        border: 2px solid #ffffff;
        border-radius: 18px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        position: absolute;
        right: 0;
        z-index: 10;
        height: 28px;
        line-height: 24px;
        width: 84px;
        text-align: center;
        ::v-deep .el-upload-dragger {
          background: none;
          border-radius: 6px;
          border: none;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
        }
      }
    }
    .detail-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -78px;
      width: 100%;
      .name {
        position: relative;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 20px;
        .sex {
          width: 16px;
          height: 14px;
          position: absolute;
          top: 5px;
          right: -26px;
        }
      }
      .honor {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ac5241;
        margin: 17px 0 0;
      }
      .intro {
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        margin-top: 49px;
      }
      .address-box {
        display: flex;
        margin: 22px 0 0px;
        img {
          width: 11px;
          height: 15px;
        }
        .address {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          margin: 0 14px 0 9px;
        }
        .follow {
          padding: 3px 8px;
          background: #e60021;
          border-radius: 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          color: #ffffff;
          height: 18px;
        }
      }
      .el-avatar--icon {
        border: 2px solid #ffffff;
      }
    }
    .personal-works {
      margin-top: 268px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .tabs {
        display: flex;
        justify-content: center;
        background: #242424;
        border-radius: 17px;
        width: 100%;
        ul {
          display: flex;
          li {
            margin: 0 52px;
            padding: 7px 0;
            cursor: pointer;
            height: 34px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            .line {
              width: 85%;
              height: 6px;
              background: #e60021;
              border-radius: 10px;
              margin-top: 20px;
              position: relative;
              &:after {
                content: " ";
                position: absolute;
                right: -8px;
                top: 0;
                width: 6px;
                height: 6px;
                background: #e60021;
                border-radius: 6px;
              }
            }
          }
        }
      }
      .info {
        margin-top: 98px;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 75px;
        }
        .title-mark:before {
          content: " ";
          position: absolute;
          left: -17px;
          top: 0px;
          width: 6px;
          height: 20px;
          background: #e60021;
          border-radius: 3px;
        }
        .info-box {
          padding-left: 35px;
          .info-con {
            display: flex;
            margin-top: 19px;
            span {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #505050;
              width: 108px;
            }
            p {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #ffffff;
              border-bottom: 1px solid #313131;
              padding-bottom: 18px;
              text-align: center;
              width: 482px;
            }
          }
        }
      }
      .picture {
        width: 100%;
        .img-checking {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 20;
          background: #000000;
          opacity: 0.6;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 36px;
            height: 36px;
            margin-bottom: 13px;
          }
          span {
            font-size: 21px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            line-height: 9px;
          }
        }
      }
      .like {
        margin-top: 20px;
        margin-left: 20px;
        width: 100%;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #ffffff;
          .more {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 100;
            color: #ffffff;
            cursor: pointer;
          }
        }
        .title-mark:before {
          content: " ";
          position: absolute;
          left: -16px;
          top: 0px;
          width: 6px;
          height: 20px;
          background: #e60021;
          border-radius: 3px;
        }
      }
    }
    .personal-hworks {
      margin-top: 298px;
    }
  }
  .comment-all-box {
    width: 100%;
    .picture-detail {
      margin-top: 107px;
      display: flex;
      .box {
        background: #1b1b1b;
        border-radius: 7px;
      }
      .browse {
        width: 436px;
        height: 517px;
        padding: 34px 33px 40px;
        margin-right: 31px;
        .browse-num {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
          img {
            width: 39px;
            height: 30px;
            margin: 0 5px;
            position: relative;
            bottom: -3px;
          }
          span {
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            margin: 0 5px;
          }
        }
        .user-item {
          text-align: center;
          margin: 20px 0 0 0;
          // height: 97px;
          cursor: pointer;
          .name {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            margin: 14px 0 10px 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .time {
            font-size: 11px;
            margin-bottom: 8px;
            color: #a25752;
            font-family: PingFang SC;
            font-weight: 300;
          }
        }
        .user-item.ellipsis {
          color: #7d7d7d;
          line-height: 96px;
          font-size: 30px;
        }
      }
      .board {
        flex: 1;
        padding-top: 30px;
        .title {
          margin-bottom: 20px;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        .title-mark:before {
          content: " ";
          position: absolute;
          left: -12px;
          top: 2px;
          width: 3px;
          height: 16px;
          background: #e60021;
          border-radius: 2px;
        }
        .board-message {
          padding: 0 60px 0 73px;
        }
        .comment-input {
          position: relative;
          flex: 1;
          margin-left: 17px;
          ::v-deep .el-textarea__inner {
            height: 136px;
            background: #4d4d4d;
            border-radius: 7px;
            color: #ffffff;
            border: none;
          }
          ::v-deep .el-input__count {
            background: #4d4d4d;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
        }
        .reply-input {
          ::v-deep .el-textarea__inner {
            height: 73px;
          }
        }
        .comment-bg {
          position: absolute;
          right: 10px;
          display: flex;
          cursor: pointer;
          img {
            width: 70px;
            height: 40px;
          }
          span {
            position: absolute;
            top: 15px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
            left: 14px;
          }
        }
        .more-comment {
          height: 58px;
          background: #141414;
          color: #595959;
          font-size: 17px;
          font-family: PingFangSC-Medium;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 0 0 7px 7px;
        }
        .no-data {
          font-size: 16px;
          text-align: center;
          padding: 60px 0;
        }
        .comment-con {
          margin-top: 115px;
          padding: 0 60px 0 55px;
          // max-height: 600px;
          overflow-y: scroll;
          .comment-list {
            color: #9e9e9e;
            font-size: 14px;
            padding-top: 25px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            &:last-child {
              border: none;
              margin-bottom: 30px;
            }
            .comment-list-level {
              display: flex;
              justify-content: space-between;
              padding-bottom: 38px;
            }
            // .comment-list-level-1 {
            // }

            .first-level-2 {
              padding-top: 17px;
            }
            .comment-item {
              display: flex;
              .comment-avatar {
                cursor: pointer;
              }
              .comment-box {
                margin-left: 17px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .name {
                  font-size: 17px;
                  font-family: PingFangSC-Medium;
                  font-weight: 500;
                  color: #c8c8c8;
                  margin-bottom: 10px;
                }
                .comment {
                  font-size: 17px;
                  line-height: 24px;
                  font-family: PingFangSC-Light;
                  color: #c8c8c8;
                  padding-right: 33px;
                }
              }
            }
            .comment-list-level-2 {
              margin-left: 60px;
              padding-bottom: 67px;
              .comment-box {
                margin-left: 8px;
              }
            }
            .comment-reply {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 300;
              color: #9e9e9e;
              display: flex;
              align-items: flex-end;
              img {
                height: 16px;
                margin-right: 9px;
                cursor: pointer;
              }
              span {
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 300;
                color: #9e9e9e;
                white-space: nowrap;
              }
              .comment-delete {
                padding-left: 10px;
              }
            }
            .my-reply {
              .form-box-left {
                display: flex;
              }
              .action-box {
                display: flex;
                align-items: center;
                margin: 0.65rem 0;
                .reply-btn-box {
                  flex: 0 0 auto;
                  margin-left: auto;
                  .reply-btn {
                    display: inline-block;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    background: #e60021;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    border-radius: 5px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .type-1 {
    height: 239px;
  }
  .img-block {
    height: 334px;
    .cover {
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }
  // .video-block {
  //   height: 239px;
  // }
  .video-box {
    width: 100%;
    padding: 60px 0;
    .video-checking {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      background: #000000;
      opacity: 0.6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 36px;
        height: 36px;
        margin-bottom: 13px;
      }
      span {
        font-size: 21px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #ffffff;
        line-height: 9px;
      }
    }
  }
  .video-list {
    margin: 0 -5px;
    .grid-col {
      padding: 0 5px;
      width: 25%;
      display: inline-block;
      .sample-player-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        overflow: hidden;
        z-index: 0;
        height: 239px;
        cursor: pointer;
        .video-player {
          display: flex;
          justify-content: center;
          align-items: center;
          .video {
            width: 100%;
            // object-fit: cover;
          }
        }
        .info-block {
          z-index: 10;
          font-size: 14px;
          position: absolute;
          color: #999;
          background-color: #080808;
          padding: 2px 6px;
          border-radius: 3px;
          opacity: 0.8;
        }
        .right-top {
          right: 10px;
          top: 8px;
        }
        .left-top {
          left: 10px;
          top: 8px;
        }
      }
      .grid-content {
        position: relative;
        margin-bottom: 10px;
        cursor: pointer;
        .grid-text, .delete-icon {
          display: none;
        }
        .grid-oper {
          position: absolute;
          bottom: 0;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: 28px;
          align-items: center;
          padding: 0 12px;
          font-size: 14px;
          .name {
            width: 50%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
          }
          img {
            height: 17px;
            cursor: pointer;
            margin-left: 8px;
          }
          span {
            margin-left: 5px;
            font-size: 17px;
            font-family: PingFang SC;
            font-weight: 300;
            color: #ffffff;
            position: relative;
            top: -3px;
          }
        }
        .current-image {
          opacity: 0;
        }
        &:hover {
          .grid-text, .delete-icon {
            display: block;
          }
        }
      }
    }
  }
  .result-item {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0;
    margin: 0;
    width: 100%;
    &:after {
      content: "";
      flex-grow: 99999;
    }
    .img-block {
      flex-grow: 1;
      transition: transform 0.3s ease;
      margin: 5px;
      height: 334px;
      // overflow: hidden;
      position: relative;
      .grid-text, .delete-icon {
        display: none;
      }
      .grid-oper {
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 28px;
        align-items: center;
        padding: 0 12px;
        font-size: 14px;
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        img {
          height: 17px;
          cursor: pointer;
          margin-left: 8px;
        }
        .m-l {
          margin-left: 8px;
          height: 19px;
        }
        span {
          margin-left: 5px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        .grid-text, .delete-icon {
          display: block;
        }
      }
    }
  }
}
.empty-style {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
//输入框样式重置
// .el-textarea .el-input__count {
//   background: #4d4d4d;
// }

.delete-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  font-size: 24px;
  cursor: pointer;
  z-index: 22;
}
</style>
